<template>
  <div>
    <el-dialog title="Select the PM Rotation" :visible.sync="show">
      <div class="rotation-content">
        <p class="select-title">Shift Time</p>
        <el-select v-model="data.shift_time" placeholder="select" style="width: 100%">
          <el-option v-for="item in shiftTimes" :key="item.shift_time_id" :label="item.name" :value="item.shift_time_id"></el-option>
        </el-select>
        <p class="select-title">PM</p>
        <el-select v-model="users" placeholder="select" style="width: 100%">
          <el-option v-for="item in user" :key="item.user_id" :label="`${item.first_name} ${item.last_name}`" :value="item.user_id"></el-option>
        </el-select>
        <div class="btn">
          <el-button class="light-blue-button" @click="show = false">Cancel</el-button>
          <el-button class="primary-button" @click="submitSetPMRotation">Confirm</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apiCreateSchedule } from "../../../API/api";
export default {
  props: ["user", "shiftTimes", "date"],
  data() {
    return {
      data:{
        users: [],
        shift_time:"",
        date:""
      },
      users:"",
      show: false,
    };
  },
  methods: {
    submitSetPMRotation() {
      this.data.users = [];
      this.data.date = this.date;
      this.data.users.push(this.users)
       apiCreateSchedule({ schedules: [this.data] }).then((res) => {
         this.$parent.getSchedulePerson()
         this.$message.success("success");
         this.users = this.data.shift_time =  "";
        }).catch((err) => {
          this.$message.error("Update schedule fail");
        }).finally(()=>{this.show = false})
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/dialog.scss";
@import "../../../css/button.scss";
.select-title {
  text-align: left;
}
.btn {
  margin-top: 20px;
  text-align: right;
}
/deep/ .el-dialog{width: 580px;}
@media (max-width:992px) {
  /deep/ .el-dialog{width: 90%;}
}
</style>