<template>
  <div class="rotaion-box">
    <p class="rotation-title">After Hours Rotation</p>
    <el-divider></el-divider>
    <div class="form">
      <p class="form-pm">PM</p>
      <el-select v-model="orderUsersID" placeholder="Select" style="width: 100%" multiple>
        <el-option v-for="(item, index) in users" :key="index" :label="`${item.first_name} ${item.last_name}`" :value="item.user_id"></el-option>
      </el-select>
      <div class="btn">
        <el-button class="primary-button" @click="settingAfterRotation">Save</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { apiCreateShiftOrder, apiGetShiftOrder } from "../../../API/api";
export default {
  props: ["users",""],
  data() {
    return {
      orderUsersID: [],
      showRotation: false,
    };
  },
  methods: {
    // 设置轮班顺序之外的人
    settingAfterRotation() {
      apiCreateShiftOrder({ users: this.orderUsersID }).then((res) => {
          this.$message.success("Update rotation successful");
        }).catch((err) => {
          this.$message.error(err.response.data.detail);
        }).finally(() => {
          this.showRotation = false;
        });
    },
  },
  created() {
    // 获取轮班顺序之外的人
    apiGetShiftOrder().then((res) => {
      this.orderUsersID = res.results.map((user) => user.user_id);
    });
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/dialog.scss";
@import "../../../css/button.scss";
@import "../../../css/init.scss";
.rotaion-box {
  .rotation-title{
    font-size: 22px;
    font-family: $fontRB;
    color: #38425B;
    margin: 0 0 0 20px;
    text-align: left;
  }
  /deep/ .el-divider--horizontal{
    margin: 10px 0;
  }
  .form {
    text-align: left;
    padding: 0 20px 20px;
    .form-pm {
      font-size: 14px;
      margin-bottom: 5px;
    }
    .btn {
      text-align: right;
      margin-top: 20px;
      .el-button {
        width: 100px;
      }
    }
  }
}
</style>