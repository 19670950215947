<template>
  <div class="schedule">
    <div class="schedule-top row">
      <span class="top-title">Agent Schedule</span>
      <el-button class="primary-button" @click="$refs.show.show = true">Set Shift Time</el-button>
    </div>
    <Set-Shift-Time ref="show" :users="users" :shiftTimes="shiftTimes" @settingShiftTime="settingShiftTime"></Set-Shift-Time>
    <div class="calendar-box pc">
      <div class="prev-month" @click="current--">
          <i class="el-icon-arrow-left"></i>&nbsp;
      </div>
      <div class="next-month" @click="current++">
          &nbsp; <i class="el-icon-arrow-right"></i>
      </div>
      <Full-Calendar ref="calendar" :config="config"  :events="data" ></Full-Calendar>
    </div>
    <div class="calendar-box move">
      <div class="prev-month" @click="current--">
          <i class="el-icon-arrow-left"></i>&nbsp;
      </div>
      <div class="next-month" @click="current++">
          &nbsp; <i class="el-icon-arrow-right"></i>
      </div>
      <Full-Calendar ref="calendarMove" :config="config" :events="dataMove"></Full-Calendar>
      <el-collapse v-model="activeNames">
        <el-collapse-item v-for="(item,index) in showMoveData" :key="index" :name="index">
          <template slot="title">{{item.date | dateFormat("MMMM DD")}} </template>
          <div  v-for="(m,t) in item.arr" :key="t" class="contant row">
            <div>
              <span  v-if="m.users.length!=0&&m.users!=undefined"><span class="item-contant" v-for="(n,i) in m.users" :key="i">{{n.user_name}}</span></span>
              
              <span class="item-contant" >{{m.shift_time&&m.shift_time.start_time!=null?m.shift_time.start_time+' - ':""}}  {{m.shift_time&&m.shift_time.end_time!=null?m.shift_time.end_time:""}}</span>
         </div>
         <i class="el-icon-close" @click="smallDel(m)"></i>
         </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <Select-PM-Rotation :user="users"  ref="rotation" :date="date" :shiftTimes="shiftTimes"></Select-PM-Rotation>
    <Del @del="delShift" :title="title" ref="delInfor"></Del>
  </div>
</template>
<script>
import "../../css/fullcalendar.css";
import { FullCalendar } from "vue-full-calendar";
import { apiGetUsers, apiGetSchedule, apiGetShiftTime, apiCreateSchedule } from "../../API/api";
import SetShiftTime from "./Component/SetShiftTimeDialog";
import SelectPMRotation from "./Component/ScheduleSelectPMRotation";
import Del from '../common/DelDialog'
import dayjs from "dayjs"
export default {
  components: { FullCalendar, SetShiftTime, SelectPMRotation ,Del },
  data() {
    return {
      data: [],
      config: {
        defaultDate: this.defaultDate, // 默认日期
        header: { left: "", center: "title", right: "" },
        defaultView: "month", // 显示默认月份
        firstDay: 0, // 以周日做为一周的开始
        showNonCurrentDates: false,//去掉不属于当月的日期
        fixedWeekCount: false,//设置周数
        dayClick: this.dayClick,
        eventStartEditable: false,//禁止事件移动
        selectable:false,//禁止拖拽选择
        eventClick:this.eventClick,
      },
      dataMove:[],
      showMoveData:[],
      users: [],
      shiftTimes: [],
      date:"",
      current: 0,
      activeNames:[],
      title:"Are you sure to delete this shift?",
      delData:{}
    };
  },
  computed: {
    defaultDate() {
      return dayjs().add(this.current, "month").format("YYYY-MM");
      },
   },
   watch: {
    defaultDate(newVal, oldVal) {
       this.getSchedulePerson()
       this.$refs.calendarMove.fireMethod("gotoDate", newVal);
       this.$refs.calendar.fireMethod("gotoDate", newVal);
      
    },},
  methods: {
    smallDel(item){
       this.delData = {
        users:[],date:item.date,shift_time:item.shift_time.shift_time_id
      }
      this.$refs.delInfor.show = true;
    },
    delShift(){
       apiCreateSchedule({ schedules: [this.delData] }).then((res) => {
         this.getSchedulePerson();
         this.$message.success("success");
        }).catch((err) => {
          this.$message.error("Update schedule fail");
        }).finally(()=>{this.show = false;this.$refs.delInfor.show = false;})

      },
    eventClick(item){
      if(item.data){
        this.delData = {
        users:[],date:item.data.date,shift_time:item.data.shift_time.shift_time_id
        }
        this.$refs.delInfor.show = true;
      }
      
    },
    settingShiftTime(){},
    dayClick(date) {
       let date_time = date.year() + "-" + (date.month() + 1) + "-" + date.date();
      this.date = dayjs(date_time).format("YYYY-MM-DD");
      this.$refs.rotation.show = true;
    },
    getSchedule() {
      // 获取轮班列表
      apiGetShiftTime().then((res) => {
        this.shiftTimes = res.results;
      });
    },
    getSchedulePerson(){
      apiGetSchedule({ year: dayjs(this.defaultDate).format("YYYY"), month: dayjs(this.defaultDate).format("MM"), limit: 93 }).then(res=>{
        this.changeData(res.results);
      })
    },
    changeData(res){
      this.data = [];
      this.dataMove = [];
      this.showMoveData = [];
      let objdata={}
      res.forEach((item,index) => {
        let name = "";
        if(item.users.length!=0){
         item.users.forEach((n,i)=>{
           if(i+1==item.users.length){ name += n.user_name;
           }else {name += n.user_name +"," }
            }) }// pc
        let obj ={start:item.date+'T'+item.shift_time.start_time, allDay: false,date:dayjs(item.date).format("MMMM DD"),data:item};
        let start_time = item.shift_time.start_time!=null?item.shift_time.start_time+" - ":""
        let end_time = item.shift_time.end_time!=null?item.shift_time.end_time:"";
        let time = start_time+end_time
        if(name||time){
        obj.title = name+"\r\n"+time;
        this.data.push(obj);
        }


        // move
        let objMove = {start:item.date, allDay: true,className:"blue"}
        this.dataMove.push(objMove)
        let o = {};
        this.dataMove = this.dataMove.reduce((cur,next) => {
              o[next.start] ? "" : o[next.start] = true && cur.push(next);
              return cur;
          },[]) ;
         let key = item.date
        if(objdata[key]){
          let s = {shift_time:item.shift_time,users:item.users,date:item.date};
          objdata[key].push(s);
        }else {
          let j = {[item.date] : [{shift_time:item.shift_time,users:item.users,date:item.date}]};
         objdata = Object.assign({},objdata,j)
        }
      });
      for(let i in objdata){
        let obj = {date:i,arr:[]};
        obj.arr=objdata[i];
        this.showMoveData.push(obj);
        }
    }
    
  },
  created() {
    this.getSchedule();
    this.getSchedulePerson()
    apiGetUsers({ user_type: ["pm"], limit: 50 }).then((res) => {
      this.users = res.results;
    });
  },
};
</script>
<style lang="scss" scoped>
@import "./Schedule.scss";
@import "../../css/button.scss";
@import "../../css/dialog.scss";
.schedule {
  padding-right: 10px;
  .schedule-top {
    margin: 20px 0 34px 0;
    justify-content: space-between;
    .top-title {
      font-size: 24px;
      font-family: "Roboto-Bold", sans-serif;
      color: #36425d;
    }
  }
  .calendar-box {
    min-height: 90vh;
    padding: 20px;
    background-color: #fff;
    position: relative;
    /deep/ .fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
      float: right;
      font-size: 16px;
    }
    /deep/ .fc-basic-view .fc-body .fc-row {
      margin-bottom: 0;
    }
    /deep/ .fc th,
    .fc td {
      height: 30px;
      line-height: 30px;
    }
  }
  .contant{
    background: #F4F9FC;text-align: left;border-left: 4px solid #678993;padding: 5px 10px 5px 10px;margin-bottom: 2px;justify-content: space-between;
    
    }
  .prev-month,.next-month {
      position: absolute;
      top: 20px;
      color: #72809d;
      left: calc(50% - 150px);
      font-size: 20px;
      i {
        font-weight: 600;
      }
    }
  /deep/ .el-collapse-item__header{font-family: 'Roboto-Bold';font-size: 16px;}
  .next-month{left: calc(50% + 120px);}
  .move{display: none;}
  @media (max-width:1200px) {
    .pc{display: none;}
    .move{display: block;}
  }
}
</style>