<template>
  <div class="set-shift-time">
    <el-dialog :visible.sync="show" title="Set Shift Times">
      <div>
        <!-- 设置时间 -->
        <div class="content">
          <div class="" v-for="(item, index) in shiftTimes" :key="index">
            <div class="row time-row">
              <span class="shift-name">{{ item.name }}</span>
              <div class="set-time">
                <p class="set-time-title">Shift Start</p>
                <el-time-picker v-model="item.start_time" value-format="HH:mm:ss" format="hh:mm A" placeholder="Arbitrary time"></el-time-picker>
              </div>
              <div class="set-time">
                <p class="set-time-title">Shift End</p>
                <el-time-picker v-model="item.end_time" value-format="HH:mm:ss" format="hh:mm A" placeholder="Arbitrary time"></el-time-picker>
              </div>
              <!-- <i v-if="index != 0" class="shift-delete el-icon-delete" @click="deleteShiftTime(index)"></i> -->
            </div>
          </div>
          <!-- <p class="new-shift"><a @click="addShiftTime">+ Add New Shift</a></p> -->
          <div class="btn">
            <el-button class="primary-button" @click="settingShiftTime">Confirm</el-button>
          </div>
        </div>
        <!-- 时间之外的代理人 -->
        <After-Hours-Rotation :users="users"></After-Hours-Rotation>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import AfterHoursRotation from "./ScheduleRotation";
import {apiCreateShiftTime,} from "../../../API/api";
export default {
  props: ["users", "shiftTimes"],
  components: { AfterHoursRotation },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    deleteShiftTime(id) {
      this.shiftTimes.forEach((item, index) => {
        if (id === index) {
          this.shiftTimes.splice(index, 1);
        }
      });
    },
    addShiftTime() {
      let obj = {
        start_time: null,
        end_time: null,
        name:"Shift" + (this.shiftTimes.length+1)
      };
      this.shiftTimes.push(obj);
    },
    settingShiftTime() {
      apiCreateShiftTime({ shift_times: this.shiftTimes }).then((res) => {
          this.$parent.getSchedule();
          this.$message.success("Update shift time successful");
        }).catch((err) => {
          this.$message.error(
            "Update shift time fail. " + err.response.data.detail
          );
        })
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/init.scss";
@import "../../../css/dialog.scss";
@import "../../../css/button.scss";
.set-shift-time {
  /deep/ .el-dialog__body {
    padding: 0;
  }
  .content {
    padding: 0 20px;
    .time-row {
      align-items: flex-end;
      text-align: left;
      .shift-name,
      .shift-delete {
        line-height: 40px;
        display: inline-block;
        width: 70px;
      }
      .shift-delete {
        width: 30px;
        color: $color1;
        &:hover {
          cursor: pointer;
        }
      }
      .set-time {
        width: calc(calc(100% - 140px) / 2);
        margin: 17px 20px 0 0;
        /deep/ .el-date-editor.el-input {
          width: 100%;
        }
        .set-time-title {
          margin: 0 0 7px 0;
        }
      }
    }
    .new-shift {
      color: $color1;
      text-align: left;
      margin-left: 70px;
      a {
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .btn {
      margin-top: 20px;
      text-align: right;
    }
  }
  /deep/ .el-dialog{width: 660px;}
@media (max-width:992px) {
  /deep/ .el-dialog{width: 90%;}
}
}
</style>